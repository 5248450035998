@import "../../assets/fonts.css";
@import "../../assets/variables.css";

.footer {
  display: grid;
  grid-template-rows: 4.2rem 3rem 2.3rem 1fr 4.5rem;
  grid-template-columns: 25rem 11.9rem 11.8rem 16.1rem 14rem;
  grid-template-areas: "title datasets models scripts about" "desc ll templ . about" "desc ll . . about" "desc . . . about" "copyright . . . .";
  color: var(--primary-dark-purple);
  font-family: OpenSans;
  background-color: var(--primary-purple);
  height: 18rem;
  font-style: normal;
  font-size: 1.5rem;
  justify-content: center;
  width: 100%;
  min-width: min-content;
  align-content: center;
  align-items: end;
  position: relative;
  bottom: 0;
}

.footer a {
  text-decoration: none;
}

.name {
  display: flex;
  height: 1.9rem;
  padding-left: .5rem;
}

.title {
  grid-area: title;
  font-size: 1.81rem;
  font-family: CocoGothic;
  text-align: center;
  width: 15rem;
  display: flex;
  flex-direction: row;
}

.data {
  grid-area: datasets;
  font-size: 1.13rem;
  cursor: pointer;
}

.ml {
  grid-area: models;
  font-size: 1.13rem;
  cursor: pointer;
}

.scripts {
  grid-area: scripts;
  font-size: 1.13rem;
  cursor: pointer;
}

.about {
  display: grid;
  grid-area: about;
  font-size: 1rem;
  grid-template-rows: 2.5rem 2.5rem 2.5rem 2.5rem;
  cursor: pointer;
  align-self: center;
  padding: 1.5rem 0 0 .3rem;
}

.desc {
  align-self: center;
  grid-area: desc;
  font-size: 1rem;
}

.ll {
  grid-area: ll;
  font-size: 1.13rem;
  cursor: pointer;
  align-self: center;
  padding-top: .8rem;
}

.templ {
  grid-area: templ;
  font-size: 1.13rem;
  cursor: pointer;
}

.notes {
  grid-area: about;
  padding: 0.45rem 0 0 0.2rem;
  cursor: pointer;
}

.confid {
  grid-area: confid;
  padding: 0.45rem 0 0 0.2rem;
  cursor: pointer;
}

.condition {
  grid-area: condition;
  padding: 0.45rem 0 0 0.2rem;
  cursor: pointer;
}

.copyright {
  grid-area: copyright;
  font-size: 0.9rem;
  align-self: center;
}
