@import "../../assets/variables.css";

html,
body {
    height: 100%;
    scrollbar-width: thin;
    /* For Mozilla Firefox */
    scrollbar-color: var(--primary-dark-green) transparent;
    /* For Mozilla Firefox */
    height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  border-radius: .4rem;
  overflow: hidden;
}

.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 1rem;
}

.table th {
  padding: .5rem 1rem;
  font-weight: bold;
}

.table th.filter > div {
  margin-bottom: .25rem;
}

.table thead {
  background-color: var(--primary-purple);
  border-top-left-radius: var(--sem-radius);
  border-top-right-radius: var(--sem-radius);
}

.table thead tr {
  font-family: OpenSans;
  color: var(--dark-blue);
}

.table thead tr th td {
  border-right: 1px solid #000;
  padding: 0;
}

.table thead tr th td div {
  width: 100%;
}

.table th > div {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: left;
  align-items: center;
  position: relative;
  height: 65%;
}

.table th span {
  display: flex;
  flex-direction: column;
  background-size: auto;
}

.thead_title {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 13rem;
  white-space: nowrap;
}

.thead_btn {
  height: .7rem;
  width: 1rem;
  padding: .3rem .5rem 0 .5rem;
}

.thead_btn:first-child {
  height: inherit;
  width: inherit;
}

.table td {
  text-align: left;
  padding: .5rem 1rem;
}

.table tbody tr:nth-child(odd) {
  font-family: OpenSans;
  background: #FFFFFF;
  color: var(--primary-dark-purple);
  border-bottom: 1px solid var(--primary-purple);
}

.table tbody {
  border: solid 1px var(--primary-purple);
}

.table tbody tr {
  font-family: OpenSans;
  background: #FFFFFF;
  color: var(--primary-dark-purple);
  border-bottom: 1px solid var(--primary-purple);
}

.table tbody tr:hover {
  background: #FFFFFF;
  box-shadow: 0px 3px 12px rgba(94, 109, 137, 0.2);
  position: relative;
  z-index: 101;
}

.td-check {
  width: 2rem;
  text-align: center;
  vertical-align: middle;
}

.sorted span {
  color: var(--warning-orange);
}

.sorted i {
  padding-left: .2rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1.3rem;
  background-color: var(--primary-purple);
  border-radius: 0 0 .4rem .4rem;
  font-size: 1rem;
  font-family: OpenSans;
  color: var(--dark-blue);
}

.page {
  display: flex;
  flex-direction: row;
  height: 1.5rem;
  align-items: center;
}

.page input {
  border: none;
  background-color: var(--primary-purple);
  appearance: textfield;
  width: 3rem;
  padding: 0;
  text-align: right;
  color: var(--dark-blue);
  text-decoration: underline dotted;
}

.page input:focus {
  background-color: var(--bg);
}

.page input::placeholder {
  color: var(--dark-blue);
  opacity: 1;
}

.next {
  width: 2rem;
  height: 100%;
}

.prev {
  width: 1.5rem;
  height: 1.5rem;
}

.select {
  appearance: none;
  border: none;
  background-color: var(--primary-purple);
  color: var(--dark-blue);
  font-size: 1rem;
  cursor: pointer;
}

.settings {
  display: block;
  position: relative;
}

.settings-columns {
  display: none;
  padding: .5rem;
  background: var(--content-bg);
  position: absolute;
  z-index: 100500;
  min-width: 25rem;
  top: 1rem;
  right: 2rem;
  border-radius: .1rem;
  box-shadow: rgba(0, 0, 0, 0.75) 0 0 .15rem;
}

.row-menu {
  position: relative;
}

.menu-name {
  display: flex;
  justify-content: center;
  font-size: 1rem;
}

.menu-list-columns {
  display: grid;
  grid-template-columns: auto auto auto;
}

.settings-columns div {
  padding: .25rem;
}
.settings button:focus + .settings-columns {
  display: flex;
  flex-direction: column;
}
