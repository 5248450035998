.select {
    color: var(--dark-green);
    font-family: OpenSans;
}

.select div {
    color: inherit;
}

.select div[class$="-control"] {
    /*width: 17.5rem;*/
    min-height: 2.6rem;
    background-color: #FFF;
    background-repeat: no-repeat;
    border: 1px solid var(--primary-green);
    box-sizing: border-box;
    border-radius: 0.4rem;
    /*padding: 0 0 0 0.7rem;*/
}

.select div[class*="option--is-selected"] {
    background-color: var(--primary-green);
}

/*.select div[class$="-indicatorSeparator"] {*/
/*    display: none;*/
/*}*/

.icon {
    padding-right: .5rem;
    width: 1.5rem;
}
