:root {
  --primary-green: #D6ECEB;
  --primary-dark-green: #73D2D4;
  --dark-green: #6D9EB6;
  --primary-purple: #D9E1F6;
  --dark-purple: #967BC2;
  --primary-dark-purple: #4F4187;
  --dark-blue: #5E6D89;
  --bg: #F5F9FC;
  --purple-light: #E8E0FF;
  --blue-light: #D6EAFF;
  --green-light: #D6F1F2;
  --sem-radius: .25rem;
  --element-height: 1.75rem;
}
