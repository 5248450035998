.crumbs {
  font-family: OpenSans;
  font-weight: normal;
  font-size: 1.1rem;
  color: var(--dark-green);
  text-decoration: none;
}

.current {
  font-family: CocoGothic;
  font-weight: normal;
  font-size: 1.75rem;
  color: var(--primary-dark-purple);
}
