.carousel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slider {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 20rem;
  overflow: hidden;
}

.slide {
  transition-duration: 1s;
  display: flex;
  align-items: center;
}

.slide img {
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  transition: .5s;
  margin-right: 2rem;
  margin-left: 2rem;
}

.btn {
  width: 3rem;
  height: auto;
  background-color: var(--bg);
  color: var(--primary-dark-purple);
  border-radius: 5%;
}

.btn:hover {
  background-color: var(--blue-light);
}
