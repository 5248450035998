@import "../../assets/fonts.css";
@import "../../assets/variables.css";

.btn {
    width: 100%;
    height: 3rem;
    background: var(--primary-dark-green);
    border-radius: 240px;
    border-width: 0;
    cursor: pointer;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
}

.btn:focus {
    outline: none;
}

.btn_theme_example {
    width: 50%;
    color: green;
    background-color: yellow;
}

.link {
    color: var(--dark-green);
    font-size: 1rem;
    font-weight: initial;
    box-shadow: 0 0 0 rgba(0,0,0,0.08), 0 0 1rem rgba(0,0,0,0.08);
    font-family: OpenSans;
    height: 1.65rem;
    background: white;
    border-radius: 5rem;
    border-width: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    padding: .2rem 1.3rem 0 1.2rem;
}

.active {
  background-color: var(--primary-green);
  color: var(--dark-green);
}

.link:focus {
  background-color: var(--primary-green);
  color: var(--dark-green);
}

.link_theme_example {
  color: gray;
  height: 1.65rem;
  background: red;
}

.themed_active {
  background-color: black;
  color: white;
}
