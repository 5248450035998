.input {
  width: 100%;
  height: 2.6rem;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: auto;
  border: 1px solid var(--primary-green);
  box-sizing: border-box;
  border-radius: 8px;
  background-position-x: 95%;
  background-position-y: 50%;
  padding: 0 0 0 0.7rem;
  color: var(--dark-green);
  font-size: inherit;
  font-family: inherit;
  grid-area: 1 / 1 / 1 / 1;
}

.input::placeholder {
  color: var(--dark-green);
  font-size: inherit;
  font-family: inherit;
}

.input:focus {
  border: 0.1rem solid var(--primary-dark-green);
  outline: none;
}

.icon {
  grid-area: 1 / 1 / 1 / 1;
  position: relative;
  align-self: center;
  left: calc(100% - 2rem);
  height: auto;
  width: auto;
}

.wrap {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.input[type='file'] {
  display: none;
}
