@font-face {
    font-family: CocoGothic;
    src: url("./fonts/CocoGothicBold.woff2") format("woff2"),
         url("./fonts/CocoGothicBold.woff") format("woff");
}

@font-face {
    font-family: OpenSans;
    src: url("./fonts/OpenSansRegular.woff2") format("woff2"),
         url("./fonts/OpenSansRegular.woff") format("woff");
}
