.full {
  width: 9rem;
  height: 9rem;
  border-radius: 8%;
}

.flex {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.wrapper {
  display: inline-block;
  height: 100%;
  width: 100%;
}
