.comment {
  display: grid;
  grid-template-rows: 1.5rem auto;
  grid-template-columns: 4rem auto auto 1fr;
  grid-column-gap: 1rem;
  grid-template-areas:
    "ava name date rating"
    "ava comm comm comm"
    "ava btn ... ...";
  min-height: 4rem;
}

.ava {
  grid-area: ava;
  height: max-content;
}

.name {
  display: block;
  font-family: OpenSans;
  font-weight: bold;
  color: var(--dark-blue);
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;
  max-width: 15rem;
  white-space: nowrap;
}

.rating {
  width: 7.4rem;
}

.comm {
  grid-column-start: 1;
  display: grid;
  align-items: baseline;
  font-family: OpenSans;
  width: 100%;
  overflow-x: auto;
  height: max-content;
  word-break: break-all;
}

.minmax {
  grid-area: comm;
  display: grid;
  overflow: hidden;
}

.buttonMinmax {
  grid-area: btn;
  display: grid;
  font-family: OpenSans;
  font-size: inherit;
  color: var(--dark-blue);
  font-weight: bold;
  cursor: pointer;
  width: 6rem;
  overflow: hidden;
}
