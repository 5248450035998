.tabs {
  display: flex;
  justify-content: space-between;
}

.tab {
  color: var(--dark-green);
  font-size: 1rem;
  font-weight: initial;
  box-shadow: 0 0 0 rgba(0,0,0,0.08), 0 0 1rem rgba(0,0,0,0.08);
  font-family: OpenSans;
  height: 1.65rem;
  background: white;
  border-radius: 5rem;
  border-width: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  padding: .2rem 1.3rem 0 1.2rem;
}

.tab label {
  cursor: pointer;
}

.tab:focus {
  background-color: var(--primary-green);
  color: var(--dark-green);
}

.tab.active {
  background-color: var(--primary-green);
  color: var(--dark-green);
}

.content {

}

.tabs2 {

}

.tabs2 .tabs {
  justify-content: left;
  margin-bottom: -3px;
}

.tabs2 .tab {
  color: var(--dark-blue);
  font-size: 1rem;
  font-weight: initial;
  font-family: OpenSans;
  height: 2.6rem;
  background: var(--bg);
  border-radius: .4rem .4rem 0 0;
  border-width: 0;
  cursor: pointer;
  text-decoration: none;
  padding: .2rem 1.3rem 0 1.2rem;
  box-shadow: none;
  align-items: center;
  display: flex;
}

.tabs2 .tab.active {
  background-color: white;
  color: var(--dark-blue);
}

.tabs2 .content {
  background-color: white;
  border-radius: .35rem;
  padding: .6rem;
  position: relative;
  z-index: 1;
}
