.radio {
  display: flex;
  width: 1.3rem;
  height: 1.3rem;
}

.input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
