@import "../../assets/variables.css";
@import "../../assets/fonts.css";

.title {
    display: flex;
    background-color: var(--primary-purple);
    height: 7.2rem;
    align-items: center;
    padding-left: 5.6rem;
}

.name {
    font-family: CocoGothic;
    font-size: 1.75rem;
    font-weight: normal;
    color: var(--primary-dark-purple);
    white-space: nowrap;
    margin-top: .2rem;
}

.desc {
    font-family: OpenSans;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    color: var(--primary-dark-purple);
    margin-left: .9rem;
}
