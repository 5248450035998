@import "../../assets/fonts.css";
@import "../../assets/variables.css";

.header {
  display: flex;
  position: fixed;
  justify-content: space-between;
  background-repeat: no-repeat;
  align-items: center;
  background-color: var(--primary-dark-purple);
  width: 100%;
  height: 4rem;
  z-index: 10;
}

.logo {
  width: 4rem;
}

.name {
  margin: 0 0 0 1rem;
  height: initial;
  width: 15rem;
}

.title {
  display: flex;
  min-width: 22rem;
  height: 100%;
  margin: 0 4rem 0 5.6rem;
  align-items: center;
  overflow: hidden;
}

.icons {
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-right: 5.3rem;
}

.icon {
  display: flex;
  align-self: center;
  height: 2.3rem;
  width: 2.3rem;
  padding-right: 2.7rem;
}
