@import "../../assets/fonts.css";

.radio_group {
  display: grid;
  grid-template-columns: 1.8rem 5rem;
  grid-template-rows: 1.3rem 1.3rem 1.3rem 1.3rem;
  font-size: 1rem;
  grid-row-gap: 0.45rem;
  margin: 0 0 0.7rem 0;
}
